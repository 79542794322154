/*
========================================================================
    Global components styling
========================================================================
*/


/*	Sections
======================================================================== */

// .section {

//     &__header {

//     }

//     &__content {

//     }

//     &__footer {

//     }
// }


/*	Gallery styling
======================================================================== */

// WP default gallery (from editor) styling
.alignleft, img.alignleft {
	margin-right: 1.5em;
	display: inline;
}
.alignright, img.alignright {
	margin-left: 1.5em;
	display: inline;
}
.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}


/*	Buttons
======================================================================== */

// .button {

// }


/*	Links
======================================================================== */

// .link {

// }


/*	Property classes
======================================================================== */

.stretched {
    //@include stretch(0,0,0,0);
}
.display--inline {
    display: inline;
}
.display--inline-block {
    display: inline-block;
}
.element {
    &--rounded {
        border-radius: $global-radius;
    }
    &--circled {
        border-radius: 50%;
    }
    &--no-border {
        border: none !important;
    }
}
.children-vertical-center {
    display: flex;
    align-items: center;
}


/*	Tables
======================================================================== */

// .table {

// }
