/*
========================================================================
    Mixins and variables
========================================================================
*/


// Mixin expanding responsive list
@mixin expand-list($list){
    @each $breakpoint, $props in $list {
        $scope: map-get($breakpoints,$breakpoint);
        @media (min-width:$scope) {
            @each $prop, $value in $props {
                #{$prop}:#{$value};
            }
        }
    }
}

// Mixin expanding a particulate nested list
@mixin expand-list-choice($list,$option) {
    @each $breakpoint,$choices in $list {
        @media (min-width:map-get($breakpoints,$breakpoint)) {
            //@debug $choices;
            $props: map-get($choices,$option);
            //@debug $props;
            @each $prop,$value in $props {
                #{$prop}: rem-calc($value);
                //@include font-size($value);
            }
        }
    }
}

// Expand a list into a specified value
@mixin expand-into-property($list,$property,$aspect:1) {
    @each $breakpoint,$value in $list {
        @media (min-width:map-get($breakpoints,$breakpoint)) {
            #{$property}:$value * $aspect;
        }
    }
}

@mixin vertical-gradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top, $top, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $top, $bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top ,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top, $bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom, $top, $bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=#{$top}, endColorstr=#{$bottom},GradientType=0 ); /* IE6-9 */
}

@mixin horizontal-gradient($direction, $left, $right){
    background: $left; /* Old browsers */
    background: -moz-linear-gradient($direction, $left, $right); /* FF3.6+ */
    background: -webkit-gradient($direction, left top, left bottom, color-stop(0%,$left), color-stop(100%,$right)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient($direction, $left, $right); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient($direction, $left ,$right); /* Opera 11.10+ */
    background: -ms-linear-gradient($direction, $left, $right); /* IE10+ */
    background: linear-gradient($direction, $left, $right); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=#{$left}, endColorstr=#{$right},GradientType=0 ); /* IE6-9 */
}

@mixin box-shadow-2($horizontal, $vertical, $blur, $spread, $color, $horizontal2, $vertical2, $blur2, $spread2, $color2) {
    -webkit-box-shadow: $horizontal $vertical $blur $spread $color, $horizontal2 $vertical2 $blur2 $spread2 $color2;
       -moz-box-shadow: $horizontal $vertical $blur $spread $color, $horizontal2 $vertical2 $blur2 $spread2 $color2;
            box-shadow: $horizontal $vertical $blur $spread $color, $horizontal2 $vertical2 $blur2 $spread2 $color2;
}

// transitions
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

// Translation
@mixin translate($percentX,$percentY) {
    -webkit-transform: translate($percentX,$percentY);
       -moz-transform: translate($percentX,$percentY);
        -ms-transform: translate($percentX,$percentY);
         -o-transform: translate($percentX,$percentY);
            transform: translate($percentX,$percentY);
}

// Box shadow
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $color;
        -moz-box-shadow:inset $top $left $blur $color;
        box-shadow:inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}