/*
========================================================================
    Utility classes
========================================================================
*/


/*	Coloring - color & background
======================================================================== */

// Generates classes from colors palette
// $foundation-palette is defined in _settings.scss
@each $name,$color in $foundation-palette {
    
    // Background colors
    // @example: bg--primary, bg-secondary, ...
    .bg--#{$name} {
        background-color: $color;
    }

    // Text colors
    // @example: color--primary, color--secondary, ...
    .color--#{$name} {
        color: $color;
    }
}


/*	Borders
======================================================================== */

$border-color: get-color(primary);
$border-thickness: 5px;

.border {

    &--top {
        border-top: $border-thickness solid $border-color;
    }

    &--right {
        border-right: $border-thickness solid $border-color;
    }

    &--bottom {
        border-bottom: $border-thickness solid $border-color;
    }

    &--left {
        border-left: $border-thickness solid $border-color;
    }
}


/*	Spacing
======================================================================== */

// Spacing palette: 0,5x, 1x, 1.5x, 2x
$spacing-palette: (
    0.5 : '-05x',
    1.0 : '',
    1.5 : '-15x',
    2.0 : '-2x',
);

// Property palette - padding, margin
$spacing-property-palette: (
    // Property     : class name
    padding         : 'padding',
    padding-top     : 'padding--top',
    padding-right   : 'padding--right',
    padding-bottom  : 'padding--bottom',
    padding-left    : 'padding--left',
    margin          : 'margin',
    margin-top      : 'margin--top',
    margin-right    : 'margin--right',
    margin-bottom   : 'margin--bottom',
    margin-left     : 'margin--left',
);

// Generates spacing classes
// @example: padding, padding-05x, padding-15x, padding--top-05xx, padding--bottom-2x, ...
// @example: margin, margin-05x, margin-15x, margin--top-05xx, margin--bottom-2x, ...
@each $multiple,$classsuffix in $spacing-palette {
    @each $property,$class in $spacing-property-palette {
        .#{$class}#{$classsuffix} {
            @include expand-into-property($base-padding-gutter,$property,$multiple);
        }
    }
}


/*	Alignment
======================================================================== */

.align-left {

    // -> TODO: Generate it depending on breakpoints
    &--large {
        @include breakpoint(large down) {
            justify-content: flex-start;
        }
    }

    &--medium {
        @include breakpoint(medium down) {
            justify-content: flex-start;
        }
    }

    &--smedium {
        @include breakpoint(smedium down) {
            justify-content: flex-start;
        }
    }

    &--small {
        @include breakpoint(small down) {
            justify-content: flex-start;
        }
    }
}

.align-center {

    &--large {
        @include breakpoint(large down) {
            justify-content: center;
        }
    }

    &--medium {
        @include breakpoint(medium down) {
            justify-content: center;
        }
    }

    &--smedium {
        @include breakpoint(smedium down) {
            justify-content: center;
        }
    }

    &--small {
        @include breakpoint(small down) {
            justify-content: center;
        }
    }
}

.align-right {

    &--large {
        @include breakpoint(large down) {
            justify-content: flex-end;
        }
    }

    &--medium {
        @include breakpoint(medium down) {
            justify-content: flex-end;
        }
    }

    &--smedium {
        @include breakpoint(smedium down) {
            justify-content: flex-end;
        }
    }

    &--small {
        @include breakpoint(small down) {
            justify-content: flex-end;
        }
    }
}

/*	Text alignment
======================================================================== */

.text-left {    // Defined in foundation

    // -> TODO: generate depending on breakpoints
    &--large {
        @include breakpoint(large down) {
            text-align: left;
        }
    }

    &--medium {
        @include breakpoint(medium down) {
            text-align: left;
        }
    }

    &--smedium {
        @include breakpoint(smedium down) {
            text-align: left;
        }
    }

    &--small {
        @include breakpoint(small down) {
            text-align: left;
        }
    }
}

.text-center {  // Defined in foundation

    // -> TODO: generate depending on breakpoints
    &--large {
        @include breakpoint(large down) {
            text-align: center;
        }
    }

    &--medium {
        @include breakpoint(medium down) {
            text-align: center;
        }
    }

    &--smedium {
        @include breakpoint(smedium down) {
            text-align: center;
        }
    }

    &--small {
        @include breakpoint(small down) {
            text-align: center;
        }
    }
}

.text-right {   // Defined in foundation

    // -> TODO: generate depending on breakpoints
    &--large {
        @include breakpoint(large down) {
            text-align: right;
        }
    }

    &--medium {
        @include breakpoint(medium down) {
            text-align: right;
        }
    }

    &--smedium {
        @include breakpoint(smedium down) {
            text-align: right;
        }
    }

    &--small {
        @include breakpoint(small down) {
            text-align: right;
        }
    }
}