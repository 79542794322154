/*
========================================================================
    Typography
========================================================================
*/
h1,h2,h3,h4,h5{
  color: black;
  font-size: 42px;
  line-height: 52px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  letter-spacing:-1px;
  @media screen and (max-width:639px) {
      &{
          font-size: 32px;
          line-height: 41px;
      }
  }
}
h2{
    font-size: 32px;
    line-height: 42px;
}
h3{
    font-size: 32px;
    line-height: 42px;
}
h4{
    font-size: 16px;
    line-height: 22px;
}
a{
    color: #58C97B;
}
.articles{
    .article{
        h2{
            line-height: 42px;
            font-size: 32px;
            @media screen and (max-width:639px) {
                &{
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
        h3{
            font-size: 20px;
            line-height: 34px;
        }
        .hidden{
            h2{
                font-size: 24px;
                line-height: 34px;
            }
        }
        .wrapper > .row > .columns > h2{
            margin-top: 0px !important;
            padding-bottom: 0px !important;
        }
    }
}
.blog{
    .blog-article{
        div:last-of-type{
            h3{
                font-size: 16px;
                line-height: 22px;
                a{
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }
}
