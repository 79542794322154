/*	Foundation
======================================================================== */

@import "grid/foundation";

// write the entire foundation on the beginning of the file
@include foundation_everything(true,false);
@include foundation-flex-classes;
@include foundation-flex-grid;


/*	Compass
======================================================================== */

// @import "compass";
// @import "compass/css3";
// @import "compass/css3/transform";
// @import "compass/layout/stretching";
// @import "compass/css3/appearance";
// @import "compass/css3/opacity";
// @import "compass/css3/transition";
// @import "compass/css3/user-interface";


/*	Theme Sass partials
======================================================================== */

@import "grid/mixins";       // Custom mixins and variables
@import "grid/typography";   // Typography styling
//@import "grid/navigation";   // Primary navigation styling
//@import "grid/footer";       // Footer styling
@import "grid/global";       // Globaly used components
@import "grid/util";         // Utilities
//@import "grid/sections";     // Sections components
//@import "grid/form";         // Forms styling

.wrapper{
    @extend .columns;
    @extend .large-10;
    @extend .large-offset-1;
}
.hidden-p{
    display: none !important;
}
.row.expanded{
    padding-left: 60px;
    padding-right: 60px;
    max-width: 1800px;
    @media screen and (max-width:1366px) {
        padding-left: 35px;
        padding-right: 35px;
    }
    @media screen and (max-width:1024px) {
        padding-left: 15px;
        padding-right: 15px;
        .column{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}
@media screen and (max-width:639px) {
    .wrapper{
        padding-left: 30px;
        padding-right: 30px;
        &.owl{
            padding-left: 0px;
            padding-right: 0px;
            overflow: hidden;
            .item{
                p{
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }
    .row.expanded{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
.gallery{
    @extend .row;
    a{
        @extend .columns;
        @extend .medium-4;
        @extend .small-6;
        margin-bottom: 15px;
    }
}
img.aligncenter{
    width: 100%;
    min-width: 100%;
    margin-bottom: 40px;
    margin-top: 40px;
    height: auto;
}
